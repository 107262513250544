window.ukTax = window.ukTax || {};
window.ukTax.uktxToast = (function () {

    "use strict";

    var notificationType = {
        positive: "positive",
        negative: "negative"
    }

    var defaultSettings = {
        selector: "body-wrapper", //UKTX main body element - Parent of toast
        text: "...",
        duration: 5000,
        gravity: "top",
        position: 'right',
        notificationType: notificationType.positive
    }

    function show(options) {
        options = mapOptions(options);
        Toastify(options).showToast();
    };

    function mapOptions(options) {
        var toastifyOptions = Object.assign({}, defaultSettings, options);
        toastifyOptions.className = toastifyOptions.notificationType || notificationType.positive; 
        return toastifyOptions;
    };

    return {
        show: show,
        showPositiveText: function (text) {
            show({
                text: text,
                notificationType: notificationType.positive
            });
        },
        showNegativeText: function (text) {
            show({
                text: text,
                notificationType: notificationType.negative
            });
        },
        notificationType: notificationType
    }
})();